import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Layout pageTitle="Hello there!">
        <p>Hi, I'm Eric! I am a QA Professional with a passion for delivering quality software.</p>
        <StaticImage 
          alt="Test image"
          src="../images/selfie.jpg"
        />
      </Layout>
    </main>
  )
}

// Step 3: Export your component
export const Head = () => <Seo title="Home Page" />

export default IndexPage